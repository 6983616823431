<template>
  <div style="background-color: white">
    <default-home-section-top />
    <default-home-section-intro />
    <default-home-section-about />
    <default-home-section-schema />
    <default-home-section-docs />
    <!-- <default-home-section-news /> -->
    <!-- <default-home-section-analytics /> -->
    <default-home-section-antikor />
    <default-home-section-footer />
  </div>
</template>
<script>
export default {
  components: {
    DefaultHomeSectionTop: () => import("@/views/Home/HomeSectionTop.vue"),
    DefaultHomeSectionIntro: () => import("@/views/Home/HomeSectionIntro.vue"),
    DefaultHomeSectionSchema: () =>
      import("@/views/Home/HomeSectionSchema.vue"),
    DefaultHomeSectionAbout: () => import("@/views/Home/HomeSectionAbout.vue"),
    DefaultHomeSectionDocs: () => import("@/views/Home/HomeSectionDocs.vue"),
    DefaultHomeSectionAnalytics: () =>
      import("@/views/Home/HomeSectionAnalytics.vue"),
    DefaultHomeSectionNews: () => import("@/views/Home/HomeSectionNews.vue"),
    DefaultHomeSectionFooter: () =>
      import("@/views/Home/HomeSectionFooter.vue"),
    DefaultHomeSectionAntikor: () => import("@/views/Home/HomeAntikorSection.vue")
  },
  data() {
    return {};
  },
};
</script>